// @flow
import React, { PureComponent } from 'react';

import Label from 'components/fragments/text/Label';
import TopBarUserConnection from 'containers/header/TopBarUserConnectionContainer';
import type { MenuElementType } from 'types/MenuElement';
import type { RouterProps } from 'types/Router';
import { dynamicClassName } from 'utils/dynamicClassName';
import { handleTagEvent } from 'utils/tagManagerUtils';

import TopBarDropdown from './TopBarDropdown';

type StateProps = {
  connectionInProgress: boolean,
  keycloakData: any,
  topBarElements: MenuElementType[],
  topNews: {
    title: string,
    link: string,
    label: string
  }
};

type Props = StateProps &
  RouterProps & {
    authenticated: boolean,
    isMobile?: boolean
  };

type State = {
  isAccordionOpen: boolean
};

class TopBar extends PureComponent<Props, State> {
  static defaultProps = {
    topBarElements: []
  };

  state = {
    isAccordionOpen: false
  };

  wrapperRef: any;

  onLinkClick = (link: string) => {
    return handleTagEvent('menu_transverse', 'clic_lien_sortant', link);
  };

  renderTopBarElements = () => {
    const { topBarElements } = this.props;
    const menus = topBarElements.filter(item => item.parent === 0);

    return menus.map<any>((menu, index) => {
      if (menu.items && menu.items.length > 0) {
        return <TopBarDropdown key={index} subMenus={menu.items} title={menu.title} />;
      }

      return (
        <li key={index}>
          <a
            href={menu.url}
            title={`Se rendre sur le site officiel "${menu.title}" de la Fédération Française de Rugby (nouvel onglet)`}
            target="_blank"
            rel="noopener noreferrer"
            onClick={this.onLinkClick(menu.url)}
          >
            {menu.title}
          </a>
        </li>
      );
    });
  };

  handleAccordionOpen = () => {
    this.setState(prevState => ({ isAccordionOpen: !prevState.isAccordionOpen }));
  };

  setWrapperRef = (node: any) => {
    this.wrapperRef = node;
  };

  render() {
    const { isMobile, topNews } = this.props;
    const { isAccordionOpen } = this.state;

    // CLASSES
    const topbarDynamicClasses = dynamicClassName('topbar');

    if (isAccordionOpen) {
      topbarDynamicClasses.add('has-accordion-open');
    } else {
      topbarDynamicClasses.remove('has-accordion-open');
    }

    return (
      <nav className="topbar">
        <ul className="topbar__left">
          {this.renderTopBarElements()}

          {topNews.title && topNews.link && (
            <>
              <li className="topbar__news">
                <a href={topNews.link} target="_blank" rel="noopener noreferrer">
                  <Label isSmall isBlueAlt label={topNews.label} />
                  <span>{topNews.title}</span>
                </a>
              </li>
            </>
          )}

          {!isMobile && <TopBarUserConnection handleUserAccordionOpen={this.handleAccordionOpen} />}

          <li className="display-desktop-only btn btn--red-dark">
            <a target="_blank" href="https://report.whistleb.com/fr/ffr" rel="noreferrer">
              J&apos;alerte
            </a>
          </li>
        </ul>
      </nav>
    );
  }
}

export default TopBar;
